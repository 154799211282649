function getResponseErrors(data) {
	let errors;

	try {
		errors = data.errors || [];
		errors = [ ...errors, ...(data.messages || []) ];
	} catch(error) {
		errors = [ error.message ];
	}

	return errors || [];
}


export default getResponseErrors;
