import React, { useEffect, useState } from 'react';
import unfilteredNavigation from '@/core/navigation';
import usePermissions from '@/hooks/usePermissions';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';


const LayoutNavigation = () => {
	const permissions                     = usePermissions(),
		  [ navigation, setNavigation ]   = useState(filterNavigation(unfilteredNavigation(), permissions)),
		  [ selectedKey, setSelectedKey ] = useState([ useLocation().pathname ]);

	useEffect(() => {
		setNavigation(filterNavigation(unfilteredNavigation(), permissions));
	}, [ permissions ]);

	const menuItems = navigation.map((nav) => {

		if(nav.single === true) {
			if(nav.single === true) {
				return {
					key:   nav.url,
					label: (<Link to={nav.url}>
						{nav.title}
					</Link>),
					title:   nav.title,
					icon:    nav.icon,
					onClick: () => setSelectedKey([ nav.url ]),
				};
			}
		}

		return {
			key:         nav.url,
			icon:        nav.icon,
			title:       nav.title,
			label:       nav.title,
			popupOffset: [ -5, 10 ],
			children: nav.children.map(child => ({
				key:   child.url,
				label: (<Link to={child.url}>
					{child.title}
				</Link>),
				icon:        child.icon,
				onClick: () => setSelectedKey([ child.url ]),
			})),
		};

	});


	return (
		<>
			<Menu
				mode="inline"
				className="nav_menu"
				selectedKeys={selectedKey}
				theme="dark"
				items={menuItems}
			/>
			<div className="sider_logo" alt={import.meta.env.VITE_COMPANY_NAME} />
		</>

	);
};



function filterNavigation(navigation, permissions) {
	return navigation
		.filter(row => row.top === true && hasRequiredPermissions(row.permissions, permissions))
		.map(row => ({
			...row,
			children: row.children.filter(child => hasRequiredPermissions((child.permissions || []), permissions)),
		}));
}



function hasRequiredPermissions(required, permissions) {
	for(let i = 0; i < required.length; i++) {
		let key = required[i];

		if(permissions[key] !== true) {
			return false;
		}
	}

	return true;
}


export default LayoutNavigation;
