/**
 * Note that this can easily be converted to a generic
 * "Base64toBlob" method, just pass in a type argument
 */

function base64toPDF(data) {
	const bufferArray = base64ToArrayBuffer(data),
		  blobStore   = new Blob([bufferArray], { type: 'application/pdf' });

	return blobStore;
}

function base64ToArrayBuffer(data) {
	const bString = window.atob(data),
		  bLength = bString.length,
		  bytes   = new Uint8Array(bLength);

	for (let i = 0; i < bLength; i++) {
		let ascii = bString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
}


export default base64toPDF;
