import React, { Suspense, useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@/core/store';
import Login from '@/core/Login';
import usePermissions from '@/hooks/usePermissions';



const WrappedRoute = ({ extras, render, ...route }) => {

	const dispatch       = useDispatch(),
		  setLayoutState = (data) => dispatch(actions.layout.setState(data)),
		  loggedIn       = useSelector(state => state.auth.loggedIn),
		  location       = useLocation().pathname,
		  permissions    = usePermissions();

	useEffect(() => {
		let update = {
			useLayout: extras.useLayout,
		};

		if(extras.title !== null) {
			update.title = extras.title;
		}

		setLayoutState(update);
	});


	if(!loggedIn && location !== '/logout' && extras.private) {
		return <Login />;
	}

	if(!hasRequiredPermissions(extras.permissions, permissions)) {
		return <div>Access Denied</div>;
	}


	return (
		<Route {...route}>
			<Suspense fallback={<div>Loading...</div>}>
				{render}
			</Suspense>
		</Route>
	);

};


function hasRequiredPermissions(required, permissions) {
	if(!Array.isArray(required)) {
		required = [];
	}

	for(let i = 0; i < required.length; i++) {
		let key = required[i];

		if(permissions[key] !== true) {
			return false;
		}
	}

	return true;
}



export default WrappedRoute;
