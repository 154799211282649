const initialState = {};


export const types = {
	SET_STATE: 'PERMISSIONS/SET_STATE',
};




const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
};
