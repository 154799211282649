import React, { lazy } from 'react';

import createRoute from '@/core/Router/createRoute';
import Logout from '@/core/Logout';

const Home           = lazy(() => import('@/core/Home')),
	  UserDetails    = lazy(() => import('@/core/UserDetails')),
	  UserManagement = lazy(() => import('@/core/UserManagement')),
	  UserGroups     = lazy(() => import('@/core/UserGroups')),
	  Permissions    = lazy(() => import('@/core/Permissions')),
	  Services       = lazy(() => import('@/core/Services')),
	  Invoices = lazy(() => import('@/core/Invoices')),
	  Tests          = lazy(() => import('@/core/_Tests'));


export let routes = [

	createRoute({
		path:    '/logout',
		exact:   true,
		render:  <Logout />,
		title:   'Log Out',
		private: true,
	}),

	createRoute({
		path:    '/user-details',
		exact:   true,
		render:  <UserDetails />,
		title:   'User Details',
		private: true,
	}),

	createRoute({
		path:    '/invoices',
		exact:   true,
		render:  <Invoices />,
		title:   'Invoices',
		private: true,
	}),

	createRoute({
		path:        '/_tests',
		exact:       true,
		render:      <Tests />,
		title:       'Tests',
		private:     true,
		permissions: [ 'superadmin' ],
	}),



	createRoute({
		path:    '/admin/user-management',
		exact:   true,
		render:  <UserManagement />,
		title:   'User Management',
		private: true,
	}),

	createRoute({
		path:    '/admin/user-groups',
		exact:   true,
		render:  <UserGroups />,
		title:   'User Groups',
		private: true,
	}),

	createRoute({
		path:    '/admin/permissions',
		exact:   true,
		render:  <Permissions />,
		title:   'Permissions',
		private: true,
	}),

	createRoute({
		path:    '/admin/services',
		exact:   true,
		render:  <Services />,
		title:   'Manage Services',
		private: true,
	}),


	createRoute({
		path:    '/',
		exact:   true,
		render:  <Home />,
		title:   'Portal Home',
		private: true,
	}),

];


function registerRoutes(newRoutes, location = 'start') {
	if(location === 'start') {
		routes = [ ...newRoutes.map(route => createRoute(route)), ...routes ];
	} else {
		routes = [ ...routes, ...newRoutes.map(route => createRoute(route)) ];
	}
}

export { registerRoutes };
