import { FolderOutlined, HomeOutlined, LogoutOutlined, PoweroffOutlined, TransactionOutlined, UserOutlined } from '@ant-design/icons';

import React from 'react';

let homeEntry = {
	title:  'Home',
	single: true,
	top:    true,
	home:   false,
	url:    '/',
	icon:   <HomeOutlined />,
};

const requiredEntries = [
	{
		title:       'My Account',
		permissions: [],
		single:      false,
		top:         true,
		home:        true,
		icon:        <UserOutlined />,
		children:    [
			{
				title:       'Invoices',
				url:         '/invoices',
				icon:        <TransactionOutlined />,
				permissions: [],
			},	
			{
				title:       'User Details',
				url:         '/user-details',
				icon:        <UserOutlined />,
				permissions: [],
			}, {
				title:       'Log Out',
				url:         '/logout',
				icon:        <LogoutOutlined />,
				permissions: [],
			},
			
		],
	}, {
		title:       'Admin',
		permissions: [ 'admin_show_menu' ],
		single:      false,
		top:         true,
		home:        true,
		icon:        <PoweroffOutlined />,
		children:    [
			{
				title:       'User Management',
				url:         '/admin/user-management',
				permissions: [ 'admin_user_management_read' ],
			},
			{
				title:       'User Groups',
				url:         '/admin/user-groups',
				permissions: [ 'admin_user_groups_read' ],
			},
			{
				title:       'Permissions',
				url:         '/admin/permissions',
				permissions: [ 'admin_permissions_read' ],
			},
			{
				title:       'Services',
				url:         '/admin/services',
				permissions: [ 'admin_manage_services' ],
			},
			{
				title:       'Test/Debug Tools',
				url:         '/_tests',
				permissions: [ 'superadmin' ],
			},
		],
	},
];

export let navigation = [];





function formatNavigation(config) {
	let nav = [
		homeEntry,
		...config,
		...requiredEntries,
	];

	return nav.map(row => ({
		title:       'Default Title',
		single:      false,
		icon:        null,
		top:         true,
		home:        true,
		...row,
		permissions: Array.isArray(row.permissions)
			? row.permissions
			: [],
		children: (row.children || []).map(child => ({
			...child,
			permissions: Array.isArray(child.permissions)
				? child.permissions
				: [],
		})),
		routes: (row.children || []).map(child => child.url),
	}));
}


function registerNavigation(navEntry) {
	let navIndex = navigation.findIndex(row => row.title === navEntry.title);

	if(navIndex === -1) {
		navigation.push(navEntry);
	} else {
		navigation[navIndex] = navEntry;
	}
}

export { registerNavigation };

let formattedNavigation = () => (formatNavigation(navigation));

export default formattedNavigation;

