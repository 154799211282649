import api from '@/api';
import base64toPDF from '@/lib/base64ToPDF';
import Cookies from '@/portal-cookie';
import { message } from 'antd';

const initialState = {
	invoices: [],
	cardCode: '',
	cardName: '',
	loading: false,
	loaded:  false,
	loadedCardCode: false,
	showInvoicePaymentDialog: false,
	showPreviewPDFDialog: false,
	pdfModalLoading: false,
	pdfModalKey: null,
	pdfModalData: null,
	pdfFilePreview: null,
};

export const types = {
	SET_STATE: 'INVOICE/SET_STATE',
};

const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	resetInvoicePDFDialog: () => ({
		type: types.SET_STATE, data: {
			showInvoicePDFDialog: false,
		},
	}),

	reset: () => ({ type: types.SET_STATE, data: { ...initialState } }),
	
	loadInvoices: () => async (dispatch, getState) => {
		const state    = getState(),
			  loading  = state.invoices.loading,
			  cardCode = state.invoices.cardCode,
			  cardType = cardCode.charAt(0);

		if(loading) {
			return false;
		}

		dispatch(actions.setState({ loading: true, loaded: false }));
		try {
			let response = await api.get('/invoices', {
				params: {
					cardCode,
					cardType,
				},
			});

			const invoices = response.map((invoice) => {
				return { ...invoice, 'PayAmount': invoice.DocumentTotal }; 
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					invoices: invoices,
					cardName: response.length > 0
						? response[0].VendorName
						: '',
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					invoices: [],
					cardName: '',
				},
			});
		}
	},
	
	loadCardCode: () => async (dispatch, getState) => {
		try {
			const userKey = Cookies.get('TS_UserKey');
			const response = await api.get('/invoices/vendor-code', {
				params: {
					userKey: userKey,
				},
			});



			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedCardCode:  true,
					cardCode: response.BPCode,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedCardCode:  true,
					cardCode: '',
				},
			});
		}
	},

	loadInvoicePreview: (docEntry, objType) => async (dispatch) => {
		dispatch(actions.setState({ pdfModalLoading: true }));
		try {
			const response = await api.get('/invoices/preview', {
				params: {
					docEntry,
					objType
				}
			});

			let fileData = response.FileData;
			let pdf = base64toPDF(fileData);

			dispatch(actions.setState({ pdfModalKey: docEntry }))
			dispatch(actions.setState({ pdfModalData: fileData }));
			dispatch(actions.setState({ pdfFilePreview: pdf }));

			dispatch(actions.setState({ pdfModalLoading: false }));
		} catch (e) {
			console.error(e);
			message.error('Could not load invoice preview');
			dispatch(actions.setState({ pdfModalLoading: false }));
		}
	},

};
