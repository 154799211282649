

export const types = {
	SET_STATE:            'LAYOUT/SET_STATE',
	SET_TITLE:            'LAYOUT/SET_TITLE',
	SET_SEARCH_TERM:      'LAYOUT/SET_SEARCH_TERM',
	TOGGLE_MENU:          'LAYOUT/TOGGLE_MENU',
	MENU_OPEN_TEMPORARY:  'LAYOUT/MENU_OPEN_TEMPORARY',
	MENU_CLOSE_TEMPORARY: 'LAYOUT/MENU_CLOSE_TEMPORARY',
};

const initialState = {
	menuCollapsed:    false,
	title:            'Page Title',
	useLayout:        false,
	appSearchTerm:    '',
	appSearchResults: null,
	appSearchPending: false,
};


const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		case types.SET_TITLE:
			if(action.title === state.title) return state;
			return { ...state, title: action.title };
		case types.SET_SEARCH_TERM:
			return { ...state, appSearchTerm: action.term };
		case types.TOGGLE_MENU:
			return { ...state, menuOpen: !state.menuOpen };
		case types.MENU_OPEN_TEMPORARY:
			return { ...state, menuOpenTemporary: true };
		case types.MENU_CLOSE_TEMPORARY:
			return { ...state, menuOpenTemporary: false };
		default:
			return state;
	}
};


export default reducers;


export const actions = {
	setState:           (data) => ({ type: types.SET_STATE, data }),
	setTitle:           (title) => ({ type: types.SET_TITLE, title }),
	toggleMenu:         () => ({ type: types.TOGGLE_MENU }),
	menuOpenTemporary:  () => ({ type: types.MENU_OPEN_TEMPORARY }),
	menuCloseTemporary: () => ({ type: types.MENU_CLOSE_TEMPORARY }),
};
