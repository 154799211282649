import React from 'react';
import { Layout as AntLayout, Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import LayoutNavigation from './LayoutNavigation';
import { actions } from './Layout.redux';

const { Header, Footer, Content, Sider } = AntLayout;
const year = new Date().getFullYear();

const Layout = (props) => {
	const dispatch      = useDispatch(),
		  loggedIn      = useSelector(state => state.auth.loggedIn),
		  title         = useSelector(state => state.layout.title),
		  menuCollapsed = useSelector(state => state.layout.menuCollapsed);

	const toggleShowMenu = () => dispatch(actions.setState({ menuCollapsed: !menuCollapsed }));


	return (
		<AntLayout>

			<Header className="ts_header">
				<Row className="ts_header_row">
					<Col>
						{
							(loggedIn && !menuCollapsed) && <MenuFoldOutlined className="ts_nav_collapse_icon" onClick={toggleShowMenu} />
						}
						{
							(loggedIn && menuCollapsed) && <MenuUnfoldOutlined className="ts_nav_collapse_icon" onClick={toggleShowMenu} />
						}
					</Col>
					<Col>
						<a href="https://vendor.allprocorp.com"><div className="header_logo" alt={import.meta.env.VITE_COMPANY_NAME} /></a>
					</Col>
					<Col  className="ts_header_title_container" flex="none">
						<Typography.Title level={2} className="ts_header_title">
							{title}
						</Typography.Title>
					</Col>
					<Col flex="auto">
						<div style={{textAlign: 'right', lineHeight: '1em', display: 'flex', height: '100%', alignItems: 'center'}}>
							<div className="ts_header_text">
								<a style={{textDecoration: 'inherit', color: 'white'}} href="mailto:vendorportalhelp@allprocorp.com">
									<span>
										<span className="ts_mobilehide">Missing invoices? Have any general concerns?</span> For assistance, contact vendorportalhelp@allprocorp.com
									</span>
								</a>
							</div>
						</div>
					</Col>
				</Row>

			</Header>


			<AntLayout>
				{loggedIn &&
				<Sider trigger={null} collapsible collapsed={menuCollapsed} className="ts_sider_container">
					<LayoutNavigation />
				</Sider>
				}

				<div className="nonheader_wrapper">
					<Content className="ts_content">
						{props.children}
					</Content>

					<Footer className="ts_footer" align="center">
						&copy; {year} ALLPRO® Corporation. All rights reserved.
					</Footer>
				</div>
			</AntLayout>


		</AntLayout>
	);
};


export default Layout;
